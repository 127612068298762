$ ->
  console.log("Ready, let's go")

  # property images height on main page
  $(window).load ->
    propertyImgHeight = $(".properties a").find("img").height()
    console.log("Property image height " + propertyImgHeight + "px")
    $(".row a").find(".overlay").height(propertyImgHeight)

  # property images height on main page
  $(window).load ->
    staffImgHeight = $(".staff a").find("img").height()
    console.log("Property image height " + staffImgHeight + "px")
    $(".row a").find(".overlay").height(staffImgHeight)

  # mobile navigation toggle
  $("p.hamburger").on "click", ->
    $("nav").slideToggle("fast")
    $(".fa-bars").toggle()
    $(".fa-close").toggle()

  # property image overlays on main page
  $(".properties a").hover(
    ->
      $(@).find(".overlay").show()
      $(@).find("img").animate({'opacity':'0.7'}, 100)
    ->
      $(@).find(".overlay").hide()
      $(@).find("img").animate({'opacity':'1'}, 100)
    )

  # staff image overlays on about page
  $(".staff a").hover(
    ->
      $(@).find(".overlay").show()
      $(@).find("img").animate({'opacity':'0.7'}, 100)
    ->
      $(@).find(".overlay").hide()
      $(@).find("img").animate({'opacity':'1'}, 100)
    )